@import "../../assets/sass/main";

.tableWrapper {
  // height: 620px;
}
.emailListHeader {
  width: 35%;
  display: flex;
  justify-content: flex-end;
  gap: 2%;

  align-items: flex-end;
  // position: sticky;
  // top:10px;
  .listTitle {
    font-weight: bold;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
  }
  .searchInput {
    display: flex;
    background-color: $white;
    // width: 20%;
    border-radius: 4px;
    border: 1px solid $lightGrey;

    .searchIcon {
      padding: 6px 10px;
      color: $lightGrey;
      display: flex;
      align-items: center;
    }
    .inputBox {
      background-color: $white;
      outline: none;
      width: 100%;
      border: none;
      font-size: 16px;
      border-radius: 4px;
    }
  }
}
.category {
  width: 20%;
}

.chipsSection {
  width: 45%;
  display: flex;
  height: 100%;
  // border: 1px solid #DDDCE1;
  border-radius: 4px;
  flex-wrap: wrap;
  gap: 10px;
  // padding: 3px 5px;
  margin: 0px 5px;
  position: relative;
}
.appliedFilters{
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  left: 10px;
  background-color: white;
  top: -10px;
  padding: 0px 3px;
  color: grey;
}
.chips {
  font-size: 12px;
  font-weight: 500;
  color: #8b93ff;
}
