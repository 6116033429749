@import "../../../assets/sass/main";

.wrapper {
  display: flex;

  padding: 10px;
  width: 100%;

  .leftContainer {
    width: 40%;

    height: 75vh;
    overflow-y: scroll;
    padding: 0 10px 0 0;

    .contentdiv {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 14px;
        font-weight: 600;
        margin: 10px 0;
        .content {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .rightContainer {
    padding-left: 10px;
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  .content {
    font-weight: 400;
    font-size: 14px;
  }
}

.leftContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.leftContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.leftContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}


.rightContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.rightContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.rightContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}
