@import "../../assets/sass/main";

.tabsWrapper {

  border: 1px solid #DDDCE1;
  margin-bottom: 1rem;
  border-radius: 4px;
  width: fit-content;
  width: 100%;
  // height: 100%;
  .tabsCatagory {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    .tabsItem {
      padding: 0px 0.5rem;
    
      border-radius: 8px;
      font-weight: bold;
      margin: 4px;
      // animation: 0.3s ease-out 0s 1 slideInLeft;
      &:hover {
        color: #8B93FF;
        background-color: $white;
      }
    }
  }
}

// @keyframes slideInLeft {
//   0% {
//     transform: translateX(20%);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }
