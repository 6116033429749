@import "../../assets/sass/main";

.tableWrapper {
  // height: 620px;

  .emailListHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    align-items: center;
    // position: sticky;
    // top:10px;
    .listTitle {
      font-weight: bold;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
    }
    .searchInput {
      display: flex;
      background-color: $white;
      width: 20%;
      border-radius: 4px;
      border: 1px solid $lightGrey;
      margin-right: 30px;
      .searchIcon {
        padding: 6px 10px;
        color: $lightGrey;
        display: flex;
        align-items: center;
      }
      .inputBox {
        background-color: $white;
        outline: none;
        width: 100%;
        border: none;
        font-size: 16px;
        border-radius: 4px;
      }
    }
  }
}
