@import "../../assets/sass/main";
.crumbs {
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: $grey;
  background-color: white;
  width: max-content;

  .crumbsLink {
    &:hover {
      text-decoration: underline;
    }
    color: #000000;
  }
}

.uploadArea {
  cursor: pointer;
  padding: 3rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  border-style: dashed;
  border-color: $purple;
  color: $purple;
  margin: 0 2rem;
  margin-bottom: 16px;
  margin-top: 2rem;
  .uploadtext1{
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }
  .uploadtext2{
    font-size: 12px;
    font-weight: 600;
    color: grey;
    margin-top: 5px;
  }
}

.wrapper {
  background-color: white;
  border-radius: 5px;
  padding: 0.5rem;
  
  .mainWrapper {
   
    // height: 60vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .uploadTextArea {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .uploadText {
        color: #000000;
        font-size: 18px;
        font-weight: 600;
      }
      .uploadsubText {
        color: grey;
        font-size: 12px;
        font-weight: 500;
        margin-top: 10px;
      }
    }
  }
}

.buttonsection{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 20px;
}

.loader {
    width: 0;
    height: 4.8px;
    display: inline-block;
    border-radius: 4px;
    position: relative;
    background: #4FD1C5;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    animation: animFw 35s linear infinite;
    margin-bottom: 10px;
  }
    .loader::after,
    .loader::before {
      content: '';
      width: 10px;
      height: 1px;
      background: #4FD1C5;
      position: absolute;
      top: 9px;
      right: -2px;
      opacity: 0;
      transform: rotate(-45deg) translateX(0px);
      box-sizing: border-box;
      animation: coli1 0.3s linear infinite;
    }
    .loader::before {
      top: -4px;
      transform: rotate(45deg);
      animation: coli2 0.3s linear infinite;
    }
  
  @keyframes animFw {
      0% {
    width: 0;
  }
      100% {
    width: 100%;
  }
    }
  
  @keyframes coli1 {
      0% {
    transform: rotate(-45deg) translateX(0px);
    opacity: 0.7;
  }
      100% {
    transform: rotate(-45deg) translateX(-45px);
    opacity: 0;
  }
    }
  
  @keyframes coli2 {
      0% {
    transform: rotate(45deg) translateX(0px);
    opacity: 1;
  }
      100% {
    transform: rotate(45deg) translateX(-45px);
    opacity: 0.7;
  }
    }
    
