.mainWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  .wrapper {
    width: 100%;

  }
}

.UploadText {
  color: #1c1c1c;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.desc {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0px;
}
