@import "../../assets/sass/main";
.listTitle {
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}
.searchInput {
  display: flex;
  background-color: $white;
  // width: 20%;
  border-radius: 4px;
  border: 1px solid $lightGrey;

  .searchIcon {
    padding: 6px 10px;
    color: $lightGrey;
    display: flex;
    align-items: center;
  }
  .inputBox {
    background-color: $white;
    outline: none;
    width: 100%;
    border: none;
    font-size: 16px;
    border-radius: 4px;
  }
}

.tabsWrapper {
  border: 1px solid #efefef;
  margin-bottom: 1rem;
  border-radius: 8px;
  width: fit-content;
  .tabsCatagory {
    display: flex;

    gap: 6px;
    padding: 2px 6px;

    .tabsItem {
      padding: 0.4rem 1.5rem;
      font-size: 14px;
      cursor: pointer;

      border-radius: 4px;
      font-weight: bold;
      margin: 4px;
      &:hover {
        color: #8b93ff;
      }
    }
  }
}
