.mainWrapper{
    .topContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .searchInput {
        display: flex;
        background-color: white;
        // width: 20%;
        border-radius: 4px;
        border: 1px solid #DDDCE1;
    
        .searchIcon {
          padding: 6px 10px;
         
          display: flex;
          align-items: center;
        }
        .inputBox {
        
          outline: none;
          width: 100%;
          border: none;
          font-size: 16px;
          border-radius: 4px;
        }
      }
}