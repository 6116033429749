@import "../../../assets//sass//main";

.topbarWrapper {
  position: sticky;
  margin-bottom: 10px;
  height: 40px;
  background-color: $white;
  border-bottom: 1px solid $line;
  width: -webkit-fill-available;

  .container {
    padding: 0.6rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .dashTitle {
      display: flex;
      align-items: center;

      .collapse {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .title {
        font-size: 16px;
        color: #8b93ff;
        font-weight: 600;
      }
    }
  }
}
