@import "../../../assets//sass//main";

.wrapper {
  width: -webkit-fill-available;

  height: 95vh;

  .dataBoardWrapper {
    height: 95%;
    overflow-y: scroll;
    padding: 0 1rem;
    &::-webkit-scrollbar {
      width: 0px;
      background-color: #f5f5f5;
    }
  }

  .numData {
    width: 100%;
  }
}
