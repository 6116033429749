.textfieldLabel {
  color: var(--Grey-15, #262626);

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-top: 0px;
  margin-bottom: 3px;
}
.singleFilter {
  border: 1px solid #dddce144;
  padding: 20px 5px;
  border-radius: 4px;
  width: max-content;
}
