@import "../../../assets//sass//main";

.sidebar {
  height: 100%;
  background-color: white;
  display: flex;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 20px;
    &::-webkit-scrollbar {
      width: 0em;
    }

    .wrapperTop {
      .brand {
        padding: 16px 16px;
        display: flex;
        align-items: center;
        gap: 5px;

        .brandTitle {
          font-weight: bold;
          font-size: 22px;
        }
      }

      .searchInput {
        margin: 16px 16px;
        display: flex;
        background-color: $white;
        border-radius: 4px;
        .searchIcon {
          padding: 6px 10px;
          color: $lightGrey;
          display: flex;
          align-items: center;
        }
        .inputBox {
          background-color: $white;
          outline: none;
          width: auto;
          border: none;
          font-size: 16px;
        }
      }
      .sidebarMenu {
        margin-top: 0px;
        font-size: 14px;
        .submenu {
          // background-color: $dash1;
          padding: 2px 0px;

          .subitem {
          }
        }
      }
    }
    .profile {
      padding-bottom: 10px;
      .profileContainer {
        padding: 16px 16px;
        border-top: 1px solid rgb(235, 231, 231);
        display: flex;
        align-items: center;
        gap: 10px;

        .avatar {
        }
        .data {
          font-size: 14px;
          .name {
            font-weight: bold;
          }
          .email {
            font-size: 12px;
          }
        }
      }
      .logoutButton {
        border: 1.5px solid #8b93ff;
        text-align: center;
        border-radius: 5px;
        padding: 5px 0px;
        margin: auto;
        width: 90%;
        color: #1c1c1c;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
    }
  }
}
.collapse {
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
  margin-right: auto;
}
.othersText {
  color: #686868;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 5px 10px;
}
