$antiflashWhite: #f5f6f8;
$whiteSmoke: #f3f3f3;
$black: #0c0c0c;
$grey: #7f7f7f;
$lightGrey: #c1c1c1;
$white: #ffffff;
$error: #b22b27;
$dash1: #f3f6fa;
$line: #dddce1;
$blue: #434ce7;
$purple: #8056d6;
$lightpurple: #8a69d0;
$sky: #e0ebfe;

$boxShadow1: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
