.filterWrapper {
  position: absolute;

  background-color: white;
  border-radius: 0px;
  border: 1px solid #dddce1;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  right: 0%;

  top: 110%;
  z-index: 100;

  .title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 5px 10px;
 
  }

  .filterSection {
    padding: 10px;
    display: flex;
    align-items: flex-end;
    gap: 30px;
  }
}

.textfieldLabel {
  color: var(--Grey-15, #262626);

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  margin-top: 0px;
  margin-bottom: 3px;
}

.singleFilter {
  border: 1px solid #dddce144;
  padding: 3px 5px;
  border-radius: 4px;

}
.topContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dddce1;
}