.mainWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  gap: 30px;
  padding: 30px 0px;
  .uploadContainer {
    width: 80%;
  }

  .recentFilesContainer {
    border-radius: 6px;
    border: 1px solid #f1f1f1;
    background: #fdfdfd;
    padding: 20px 15px;
    width: 80%;

    .recentText {
      color: #1c1c1c;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      padding-bottom: 10px;
      border-bottom: 1px solid #c2c2c2;
      margin-bottom: 10px;
    }

    .dataContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0px;
      .dataLeftContainer {
        display: flex;
        align-items: center;
        gap: 10px;

        .details {
          .fileName {
            color: rgba(0, 0, 0, 0.8);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: lowercase;
          }
          .fileSize {
            color: rgba(0, 0, 0, 0.6);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .dataRightContainer {
        display: flex;
        align-items: center;
        gap: 20px;
        .reEditButton {
          border-radius: 2px;
          background: #5a6acf;
          padding: 10px 30px;
          border: 1px solid #5a6acf;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          cursor: pointer;
        }
        .removeButton {
          border-radius: 2px;
          background: white;
          padding: 10px 30px;
          border: 1px solid #5a6acf;
          color: #5a6acf;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}
