@import "../../assets/sass/main";

.crumbs {
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: $grey;
  background-color: #f8f9fa;
  width: max-content;
  font-size: 12px;
  .crumbsLink {
    &:hover {
      text-decoration: underline;
    }
    color: #000000;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-bottom: 2rem;
  background-color: #f8f9fa;
  padding: 0.5rem;

  .section1 {
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 16px;
    background-color: white;

    .header {
      padding: 0.1rem;

      // border-bottom: 1px solid $line;
      font-weight: 700;
      background-color: #afb4fdb8;
      font-size: 17px;
      text-align: center;
      border-radius: 16px 16px 0 0;
    }
    .fields {
      padding-top: 0.5rem;
      .title {
        font-size: 13px;
        margin-bottom: 6px;
        color: rgb(64, 63, 63);
      }
      .title2 {
        font-size: 13px;
        margin-bottom: 6px;
        color: rgb(64, 63, 63);
        font-weight: bold;
      }
      .textfield {
        padding: 0.5rem 1rem;
        background-color: rgba(240, 240, 240, 0.954);
        border-radius: 6px;
        margin-bottom: 1rem;
        font-weight: bold;
      }
      .textfieldAddress {
        padding: 0.5rem 1rem;
        background-color: rgba(240, 240, 240, 0.954);
        border-radius: 6px;
        margin-bottom: 1rem;
        font-weight: bold;
        // min-height: 100px;
      }
      .textfieldTableData {
        // border-bottom: 1px solid $line;
        padding: 0.5rem 0 0.5rem 0;
        .textfieldTable {
          margin-bottom: 0.5rem;
          font-weight: bold;
        }
      }
      .card {
        border-bottom: 1px solid $line;
        margin-bottom: 0.5rem;
        font-weight: bold;
        .bankName {
          font-weight: bold;
          padding: 0 0.3rem;
        }
        .branchName {
          font-size: 14px;
          padding: 0.3rem;
          .bankTitle {
            font-weight: bold;
            font-size: 13px;
          }
        }
        .bankCode {
          display: flex;
          padding: 0.3rem;
          justify-content: space-between;
          margin-right: 10rem;
          font-size: 14px;
          font-weight: bold;
          .bankTitle {
            font-weight: bold;
            font-size: 13px;
          }
        }
      }
    }

    .fields1 {
      padding: 0 0.5rem;
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
      .textfield1 {
        padding: 0.5rem 1rem;
        background-color: rgba(240, 240, 240, 0.954);
        border-radius: 6px;
        font-weight: bold;
        min-width: 25%;
      }
      .textfieldnone {
        display: none;
      }
    }
  }
}

.displayWrapper {
  display: flex;
  justify-content: space-between;
}

.section1Left {
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 16px;
  background-color: white;
  width: 70%;
  .header {
    padding: 0.1rem;
    // border-bottom: 1px solid $line;
    font-weight: 700;
    background-color: #afb4fdb8;
    font-size: 17px;
    text-align: center;
    border-radius: 16px 16px 0 0;
  }
  .fields {
    padding: 1rem;
    .title {
      font-size: 13px;
      margin-bottom: 6px;
      color: rgb(64, 63, 63);
    }
    .title2 {
      font-size: 13px;
      margin-bottom: 6px;
      color: rgb(64, 63, 63);
      font-weight: bold;
    }
    .textfield {
      padding: 0.5rem 1rem;
      background-color: rgba(240, 240, 240, 0.954);
      border-radius: 6px;
      margin-bottom: 1rem;
      font-weight: bold;
    }
    .textfieldAddress {
      padding: 0.5rem 1rem;
      background-color: rgba(240, 240, 240, 0.954);
      border-radius: 6px;
      margin-bottom: 1rem;
      font-weight: bold;
      // min-height: 100px;
    }
    .textfieldTableData {
      border-bottom: 1px solid $line;
      padding: 0.5rem 0 0.5rem 0;
      .textfieldTable {
        margin-bottom: 0.5rem;
        font-weight: bold;
      }
    }
    .card {
      border-bottom: 1px solid $line;
      margin-bottom: 0.5rem;
      font-weight: bold;
      .bankName {
        font-weight: bold;
        padding: 0 0.3rem;
      }
      .branchName {
        font-size: 14px;
        padding: 0.3rem;
        .bankTitle {
          font-weight: bold;
          font-size: 13px;
        }
      }
      .bankCode {
        display: flex;
        padding: 0.3rem;
        justify-content: space-between;
        margin-right: 10rem;
        font-size: 14px;
        font-weight: bold;
        .bankTitle {
          font-weight: bold;
          font-size: 13px;
        }
      }
    }
  }
  .fields1 {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    .textfield1 {
      padding: 0.5rem 1rem;
      background-color: rgba(240, 240, 240, 0.954);
      border-radius: 6px;
      font-weight: bold;
      min-width: 25%;
    }
    .textfieldnone {
      display: none;
    }
  }
}
.section1Right {
  // box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 16px;
  background-color: white;
  width: 29%;
  .header {
    padding: 0.1rem;
    // border-bottom: 1px solid $line;
    font-weight: 700;
    background-color: #afb4fdb8;
    font-size: 17px;
    text-align: center;
    border-radius: 16px 16px 0 0;
  }
  .fields {
    padding: 1rem;
    .title {
      font-size: 13px;
      margin-bottom: 6px;
      color: rgb(64, 63, 63);
    }
    .title2 {
      font-size: 13px;
      margin-bottom: 6px;
      color: rgb(64, 63, 63);
      font-weight: bold;
    }
    .textfield {
      padding: 0.5rem 1rem;
      background-color: rgba(240, 240, 240, 0.954);
      border-radius: 6px;
      margin-bottom: 1rem;
      font-weight: bold;
    }
    .textfieldAddress {
      padding: 0.5rem 1rem;
      background-color: rgba(240, 240, 240, 0.954);
      border-radius: 6px;
      margin-bottom: 1rem;
      font-weight: bold;
      // min-height: 100px;
    }
    .textfieldTableData {
      border-bottom: 1px solid $line;
      padding: 0.5rem 0 0.5rem 0;
      .textfieldTable {
        margin-bottom: 0.5rem;
        font-weight: bold;
      }
    }
    .card {
      border-bottom: 1px solid $line;
      margin-bottom: 0.5rem;
      font-weight: bold;
      .bankName {
        font-weight: bold;
        padding: 0 0.3rem;
      }
      .branchName {
        font-size: 14px;
        padding: 0.3rem;
        .bankTitle {
          font-weight: bold;
          font-size: 13px;
        }
      }
      .bankCode {
        // display: flex;
        padding: 0.3rem;
        // justify-content: space-between;
        // margin-right: 10rem;
        font-size: 14px;
        font-weight: bold;
        .bankTitle {
          font-weight: bold;
          font-size: 13px;
        }
      }
    }
  }
  .fields1 {
    padding: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    .textfield1 {
      padding: 0.5rem 1rem;
      background-color: rgba(240, 240, 240, 0.954);
      border-radius: 6px;
      font-weight: bold;
      min-width: 25%;
    }
    .textfieldnone {
      display: none;
    }
  }
}

.fieldsBank {
  padding: 0.5rem 0rem;
  display: flex;

  // border-right: 1px solid #e1e2e3;
  .title {
    font-size: 13px;
    margin-bottom: 3px;
    color: rgb(64, 63, 63);
  }
  .title2 {
    font-size: 13px;
    margin-bottom: 6px;
    color: rgb(64, 63, 63);
    font-weight: bold;
  }
  .textfield {
    padding: 0.5rem 1rem;
    background-color: rgba(240, 240, 240, 0.954);
    border-radius: 6px;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  .textfieldAddress {
    padding: 0.5rem 1rem;
    background-color: rgba(240, 240, 240, 0.954);
    border-radius: 6px;
    margin-bottom: 1rem;
    font-weight: bold;
    // min-height: 100px;
  }
  .textfieldTableData {
    // border-bottom: 1px solid $line;
    padding: 0.5rem 0 0.5rem 0;
    .textfieldTable {
      margin-bottom: 0.5rem;
      font-weight: bold;
    }
  }
  .card {
    // border-bottom: 1px solid $line;
    border-right: 1px solid #e1e2e3;
    margin-bottom: 0.5rem;
    padding: 0rem 0.5rem;
    font-weight: bold;
    width: 33%;
    .bankName {
      font-weight: bold;
      padding: 0 0.3rem;
      margin-bottom: 10px;
      text-decoration: underline;
      margin-left: 5px;
    }
    .branchName {
      font-size: 12px;
      padding: 0.3rem 0rem;
      .bankTitle {
        font-weight: bolder;

        font-size: 13px;
      }
    }
    .bankCode {
      display: flex;
      padding: 0.3rem 0;
      justify-content: space-between;
      // margin-right: 10rem;
      font-size: 12px;
      font-weight: bold;
      .bankTitle {
        font-weight: bolder;
        font-size: 13px;
      }
    }
  }
}

.checkboxsection {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e2e3;
  border-radius: 5px;
  padding: 6px 10px;
  width: 100%;
  margin: 5px;
  justify-content: space-between;
  // cursor: not-allowed;
  .checkboxsectionName {
    font-size: 13px;
    font-weight: 700;
    margin-right: 10px;
  }
}
.fieldsNom {
  padding: 0.5rem;
  border: 1px solid #aaabac;
  margin-bottom: 0.2rem;
  border-radius: 6px;
}
