@import "../../assets/sass/main";
.wrapper {
  // margin-top: 2rem;
  background: white;
  padding: 0.5rem;
  .topbar {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 1.5rem;
    align-items: center;
    .totalClients {
      font-weight: bold;
      font-size: 22px;

      color: grey;
    }
    .searchInput {
      position: relative;
      background-color: #f4f7f9;
      border: 1px solid #e1e2e3;
      width: 40%;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      transition: all 0.3s ease;
      &:focus-within {
        width: 40%;
      }

      .searchIcon {
        padding: 10px 14px;
        color: $lightGrey;
        display: flex;
        align-items: center;
      }
      .inputBox {
        background-color: #f4f7f9;
        outline: none;
        width: 100%;
        border: none;
        font-size: 16px;
        border-radius: 8px;
      }
      .searchSuggestWrap {
        position: absolute;
        width: 100%;
        z-index: 100;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        border-radius: 12px;

        .searchSuggestItem{
          font-size: 16px;
          padding: 8px 19px;
          &:hover{
            background-color: #f4f7f9;
          }
        }
      }
    }
  }

  .tablewrapper {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
}
.userNameWrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  .userName {
    display: flex;
    flex-direction: column;
  }
  .location {
    font-size: 11px;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  .contactMail {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .contactPhone {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #4f5b67;
  }
}
.status {
  padding: 0.5rem 0.6rem;
  background-color: rgb(245, 223, 223);
  width: 6rem;
  text-align: center;
  border-radius: 8px;
  border: 2px solid red;
  font-size: 13px;
  color: red;
  font-weight: bold;
}
.statusGreen {
  padding: 0.5rem 1rem;
  background-color: #007e73;
  width: 6rem;
  text-align: center;
  border-radius: 8px;

  font-size: 13px;
  color: #ffffff;
  font-weight: bold;
}
.statusOrange {
  padding: 0.5rem 0.6rem;
  background-color: rgb(249, 227, 202);
  width: 6rem;
  text-align: center;
  border-radius: 8px;
  border: 2px solid rgb(255, 137, 78);
  font-size: 13px;
  color: rgb(247, 113, 46);
  font-weight: bold;
}

.uploadArea {
  cursor: pointer;
  padding: 3rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  border-style: dashed;
  border-color: $purple;
  color: $purple;
  margin: 0 2rem;
  margin-bottom: 16px;
  margin-top: 2rem;
}
