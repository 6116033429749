@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root,
body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
}

.ps-sidebar-container {
  &::-webkit-scrollbar {
    width: 0em;
  }
}
.recharts-layer.recharts-pie,
.recharts-sector:focus {
  outline: none !important;
  outline-color: none;
  outline-style: none;
  outline-width: none;
}

.react-confirm-alert-button-group {
  justify-content: flex-end !important;
  margin-top: 20px;
  display: flex;
}

.react-confirm-alert-overlay {
  background: rgba(85, 84, 84, 0.562) !important;
  animation: react-confirm-alert-fadeIn 0.2s 0.2s forwards;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

@media (max-width: 468px) {
  .react-confirm-alert-body {
    width: 90%;
    padding: 10px;
    margin: auto;
  }
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.confitmbutton {
  outline: none;
  background: #8b93ff;
  border: none;
  // display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

.confitmalertUI {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}
.MuiDialog-paper {
  max-width: unset !important;
}
