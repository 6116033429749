@import "../../assets/sass/main";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;

  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .modalContainer {
      // box-shadow: $boxShadow1;
      border: 1.3px solid #d6d7dd;
      padding: 30px 20px;
      display: flex;
      width: 70%;
      height: 80%;
      border-radius: 10px;
      overflow: hidden;
      transition-duration: 0.3s;
      align-items: center;
      @media (min-width: 1500px) {
        width: 50%;
      }

      .modalLeft {
        display: flex;
        width: 60%;
        flex-direction: column;

        padding: 0px 5%;
        background: $white;
        // flex: 1.5;
        transition-duration: 0.5s;
        opacity: 1;

        .modalTitle {
          font-size: 40px;
          font-style: normal;
          font-weight: 800;
          line-height: 47.451px;
          background: linear-gradient(90deg, #8b93ff 0%, #353ca2 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-bottom: 20px;
        }
        .modalSubTitle {
          color: var(--black-9001-a-1-a-1-a, #1a1a1a);
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 69.892px;
        }

        .loginData {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: -webkit-fill-available;
          .label {
            color: var(--black-800333333, #333);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 29.954px; /* 124.807% */
            letter-spacing: 0.749px;
          }
          .inputBlock {
            display: flex;
            flex-direction: column;
            // height: 64px;
            margin-bottom: 5px;
            transition: 0.3s;
            .formError {
              font-size: 13px;
              color: $error;
              margin-left: 10px;
            }
          }

          .modalButtons {
            font-size: 14px;
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            gap: 10px;
            .link {
              text-decoration: none;
            }
          }
          .signUp {
            margin: 60px 0 0;
            font-size: 14px;
            text-align: center;
            color: $grey;
            .link {
              text-decoration: none;
              color: #383838;
            }
          }
        }
      }
      .modalRight {
        width: 40%;
        height: 100%;
        .signupImage {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
