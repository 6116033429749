.uploadArea {
    cursor: pointer;
    padding: 2rem 3rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    border-style: dashed;
    border-color: #8056d6;
    color: #8056d6;
    margin: 0 2rem;
    margin-bottom: 16px;
    margin-top: 1rem;
    width: 400px;
  }

  .fileDetails{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .fileName{
        font-size: 12px;
        font-weight: 600;
    }
  }
  