.wrapper {
  width: 100%;
  border: 1px solid #c5c5cc;
  height: calc(100vh - 70px);
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 5px;
  .topContainer {
     width: 100%;
    height: 50px;
   
    border-bottom: 1px solid #c5c5cc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    .topLeftContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      .fileName {
        color: #1c1c1c;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
    }
    .topRightContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }
}

.mainWrapper {
  display: flex;
  width: 100%;
  // background-color: grey;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  position: relative;
}

.centerSection {
  width: 100%;
  max-width: 595px;
  background-color: rgb(241, 241, 241);
  height: 100%;

  position: relative;
}

.right {
  height: calc(100vh - 120px);
  padding: 20px 10px;
  width: 20%;
  overflow-y: hidden;
  position: sticky;
  top: 0px;
}

.rightSection {

  width: 100%;
   height: 100%;
  padding: 10px;

  overflow-y: scroll;
  border: 0.5px solid #8b93ff;
  //   margin:auto 5px;
  border-radius: 4px;
}
.center {
  margin: auto;
  padding: 20px 0px;
  display: flex;
  width: 60%;
  justify-content: center;
  background-color: #efefef;
}

.previewSection {
  position: sticky;
  top: 0px;
  width: 20%;
  height: calc(100vh - 120px);
  overflow-y: scroll;
  border-right: 1px solid #dddce1;
  overflow-x: hidden;
}

.feildDetailsSection{
    padding: 10px 0px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-radius: 0px;
    font-weight: 500;

    cursor: pointer;
    border-bottom: 0.5px solid #D1D1D1;
}

.coordinatesText {
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}
.backButton{
    background-color:#8b93ff;
    color: white;
    padding: 5px 5px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.imageSection {
  width: 100%;
  height: 100%;
  gap: 2px;
}

.image {
  width: 100%;
  height: 100%;
  max-height: 842px;
  object-fit: cover;
  position: relative;
}

.imagePreviewSection {
  width: 90%;
  height: 100%;
  gap: 5px;
  margin: auto;
  padding: 20px 0px;
}

.imagePreviewimg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
  border: 1px solid #b9b9b9;
  border-radius: 0px;
  cursor: pointer;
  /* filter: brightness(80%); */
}

.imagePreviewimg:hover {
  border: 1px solid #b9b9b9;
}

.rightSection::-webkit-scrollbar {
  width: 0px;
}
.mainWrapper::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.rightSection::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.rightSection::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}

.previewSection::-webkit-scrollbar {
  width: 0px;
}

/* Track */
.previewSection::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.previewSection::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 2px;
}
