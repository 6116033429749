@import "../../assets/sass/main";

.topContainerTitle {
  height: 10%;
  margin-bottom: 10px;

  .title {
    width: 95%;
    background: #f3f4ff;
    border-radius: 4px;
    padding: 10px 10px;
  }
}

.buttonWrapper {
  height: 9%;
  border: 1px solid #dddce1;
  background-color: #fbfbfb;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.wrapper {
  display: flex;
  height: 80%;
  padding: 10px;
  width: 100%;

  .leftContainer {
    width: 40%;

    height: 100%;
    overflow-y: scroll;
    padding: 0 10px 0 0;
    .clientInfo {
      color: #000;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      border-bottom: 1px solid #dddce1;
      width: 100%;
      padding: 10px 0px;
    }

    .contentdiv {
      display: flex;
      justify-content: space-between;
      .title {
        font-size: 14px;
        font-weight: 600;
        margin: 10px 0;
        .content {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }
  }
  .rightContainer {
    padding-left: 10px;
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    width: 100%;
    margin: 3% 0px 0px 0px;
  }
}

.title {
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0;
  .content {
    font-weight: 400;
    font-size: 14px;
  }
}

.leftContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.leftContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.leftContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.rightContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.rightContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.rightContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

.tabsContainer {
  overflow-y: scroll;
  height: 100%;
}

.tabsContainer::-webkit-scrollbar {
  width: 1px;
}

.tabsWrapper {
  border: 1px solid #efefef;
  position: absolute;
  top: -50px;
  border-radius: 8px;
  width: fit-content;
  margin: auto;
  .tabsCatagory {
    display: flex;
    position: absolute;
    gap: 6px;
    padding: 2px 6px;

    .tabsItem {
      padding: 0.4rem 1.5rem;
      font-size: 14px;
      cursor: pointer;

      border-radius: 4px;
      font-weight: bold;
      margin: 4px;
      &:hover {
        color: #8b93ff;
      }
    }
  }
}
