@import "../../assets/sass/main";

.tabsWrapper {
  background-color: $dash1;
  margin-bottom: 1rem;
  border-radius: 8px;
  width: fit-content;
  .tabsCatagory {
    display: flex;

    gap: 6px;
    padding: 2px 6px;
    .tabsItem {
      padding: 0.4rem 1.5rem;
      font-size: 14px;
      cursor: pointer;

      border-radius: 4px;
      font-weight: bold;
      margin: 4px;
      &:hover {
        color: #8b93ff;
      }
    }
  }
}

// @keyframes slideInLeft {
//   0% {
//     transform: translateX(20%);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }
