.mainWrapper {
  // padding: 20px 40px;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;

  .imageTitleContainer {
    background: #afb4fdb8;
    padding: 20px 0;
    border-radius: 12px 12px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 18px;
      font-weight: 500;
      color: white;
    }
  }
  .bodyContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 50px;
    .bodyImage {
      width: 100px;
    }
    .bodTitle {
      font-size: 18px;
      font-weight: 500;
      color: rgba(84, 89, 94, 1);
      margin: 15px 0;
    }
    .bodyPara {
      font-size: 14px;
      font-weight: 400;
      color: rgba(84, 89, 94, 0.6);
      text-align: center;
      margin-bottom: 20px;
    }

    .buyNowButtonSection {
      margin-bottom: 15px;
    }
  }
}

.fieldsWrapper {
  margin: 10px 20px 40px 20px;
  .text {
    font-size: 14px;
    color: #54595e;
    font-size: 500;
    text-align: center;
    margin-bottom: 20px;
  }
}
