@import "../../assets/sass/main";

.wrapper {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100vw;
  height: 100vh;
}

.mainWrapper::-webkit-scrollbar {
  height: 0px;
}
