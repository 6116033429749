@import "../../assets/sass/main";

.tabsWrapper {
  border: 1px solid #efefef;
  margin-bottom: 1rem;
  border-radius: 8px;
  width: fit-content;
  .tabsCatagory {
    display: flex;

    gap: 6px;
    padding: 2px 6px;

    .tabsItem {
      padding: 0.4rem 1.5rem;
      font-size: 14px;
      cursor: pointer;

      border-radius: 4px;
      font-weight: bold;
      margin: 4px;
      &:hover {
        color: #8b93ff;
      }
    }
  }
}
.data1 {
  border-radius: 10px;
  padding: 10px;
  border: 1px solid #ebecef;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 20px;
  .data1Left {
    width: 30%;
    background: #ebf1fc;
    border-radius: 4px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .data1Right {
    width: 70%;
    height: 100%;
    .dataTitle {
      color: #4e5564;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
    .dataInfo {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .dataNumInfo {
        color: #40444d;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        margin-top: 5px;
      }
    }
  }

  .dataFooter {
  }
}

.deliveryTitle {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.deliveryGraph {
  padding: 0 1.5rem;
  border-bottom: 1px solid $line;
  border-right: 1px solid $line;
  .dgTitle {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    height: 42px;
  }
  .dgRate {
    font-size: 30px;
    font-weight: bold;
  }
  .dgInfo {
    margin-bottom: 1rem;
  }
  .dgPlot {
  }
}
.deliveryGraph1 {
  padding: 0 1.5rem;
  border-bottom: 1px solid $line;

  .dgTitle {
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    height: 42px;
  }
  .dgRate {
    font-size: 30px;
    font-weight: bold;
  }
  .dgInfo {
    margin-bottom: 1rem;
  }
  .dgPlot {
  }
}
.emailData {
  border: 1px solid #ebecef;
  padding: 10px;
  .edTitle {
    color: #181c32;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .edDatakey {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
    height: 20px;

    .edDatakey1 {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 8px;
      .edDatakeyCircle1 {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: white;
        border: 4px solid #ffc088;
      }
      .edDatakeyTitle {
        color: $grey;
        font-size: 12px;
      }
    }
    .edDatakey2 {
      display: flex;
      align-items: center;
      gap: 8px;
      .edDatakeyCircle2 {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: white;
        border: 4px solid #adb5e7;
      }
      .edDatakeyTitle {
        color: $grey;
        font-size: 12px;
      }
    }
    .edDatakey3 {
      display: flex;
      align-items: center;
      gap: 8px;
      .edDatakeyCircle2 {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: white;
        border: 4px solid #3e3e3e;
      }
      .edDatakeyTitle {
        color: $grey;
        font-size: 12px;
      }
    }
  }
}
.emailDataKRA {
  border: 1px solid #ebecef;
  padding: 10px;
  .edTitle {
    color: #181c32;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .edDatakey {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 28px;
    height: 20px;

    .edDatakey1 {
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 8px;
      .edDatakeyCircle1 {
        width: 25px;
        height: 10px;
        border-radius: 10px;
        background-color: #ffc088;
        border: 3px solid #ffc088;
      }
      .edDatakeyTitle {
        color: $grey;
        font-size: 12px;
      }
    }
    .edDatakey2 {
      display: flex;
      align-items: center;
      gap: 8px;
      .edDatakeyCircle2 {
        width: 25px;
        height: 10px;
        border-radius: 10px;
        background-color: #adb5e7;
        border: 3px solid #adb5e7;
      }
      .edDatakeyTitle {
        color: $grey;
        font-size: 12px;
      }
    }
    .edDatakey3 {
      display: flex;
      align-items: center;
      gap: 8px;
      .edDatakeyCircle2 {
        width: 25px;
        height: 10px;
        border-radius: 10px;
        background-color: #3e3e3e;
        border: 3px solid #3e3e3e;
      }
      .edDatakeyTitle {
        color: $grey;
        font-size: 12px;
      }
    }
  }
}
.performanceData {
  padding: 10px;
  border: 1px solid #ebecef;
  .pdTitle {
    color: #181c32;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  .edDatakey {
    display: flex;
    align-items: center;
    gap: 28px;
    margin-bottom: 2rem;
    .edDatakey1 {
      display: flex;
      align-items: center;
      gap: 8px;
      .edDatakeyCircle1 {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $purple;
      }
      .edDatakeyTitle {
        color: $grey;
        font-size: 12px;
      }
    }
    .edDatakey2 {
      display: flex;
      align-items: center;
      gap: 8px;
      .edDatakeyCircle2 {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $blue;
      }
      .edDatakeyTitle {
        color: $grey;
        font-size: 12px;
      }
    }
  }
}
.analytics {
  color: #000;

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 15px 10px;
}
